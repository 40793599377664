import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'

import Website from './pages/Website'
import Privacy from './pages/Privacy'
import { ROUTES } from './constants/routes'

import './App.scss'

import './assets/fonts/Rajdhani-Light.ttf'
import './assets/fonts/Rajdhani-Medium.ttf'
import './assets/fonts/Rajdhani-Regular.ttf'
import './assets/fonts/Rajdhani-SemiBold.ttf'
import './assets/fonts/Rajdhani-Bold.ttf'
import Terms from 'pages/TermsAndConditions'
import EULAPage from 'pages/EULA'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path={ROUTES.WEBSITE.PATH} element={<Website />} />

          <Route path={ROUTES.PRIVACY.PATH} element={<Privacy />} />

          <Route path={ROUTES.TERMS.PATH} element={<Terms />} />

          <Route path={ROUTES.EULA.PATH} element={<EULAPage />} />

          <Route path='/.well-known/apple-app-association-file' element={<div></div>} />

          {/* wild route*/}
          <Route path='*' element={<Navigate to={ROUTES.WEBSITE.PATH} replace />} />
        </Routes>
      </Router>
    </>
  )
}

export default App
