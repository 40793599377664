import './Footer.scss'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-actions'>
        <a
          className='footer-actions-terms'
          href='/terms-and-conditions'
          target='_self'
          rel='noopener noreferrer'
        >
          Terms and Conditions
        </a>
        <a
          className='footer-actions-privacy'
          href='/privacy'
          target='_self'
          rel='noopener noreferrer'
        >
          Privacy
        </a>
        <a className='footer-actions-eula' href='/eula' target='_self' rel='noopener noreferrer'>
          EULA
        </a>
        <a style={{ width: '134px' }}></a>
      </div>
      <div className='footer-text'>
        <p>
          © Copyrights{' '}
          <a href='https://nistruct.com/' target='_blank' rel='noopener noreferrer'>
            Nistruct 2020
          </a>
          . All rights reserved. Patents pending.
        </p>
      </div>
    </div>
  )
}

export default Footer
