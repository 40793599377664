export const ROUTES = {
  PORTAL: {
    KEY: 'portal-route',
    TITLE: 'portal',
    PATH: '/portal',
  },
  AUTHORIZED: {
    KEY: 'authorized-route',
    TITLE: 'Authorized',
    PATH: '/authorized',
  },
  FINANCIALS: {
    KEY: 'financials-route',
    TITLE: 'Financials',
    PATH: 'financials',
  },
  WELCOME: {
    KEY: 'welcome-route',
    TITLE: 'Welcome',
    PATH: '/welcome',
  },
  WEBSITE: {
    KEY: 'website-route',
    TITLE: 'Website',
    PATH: '/website',
  },
  PRIVACY: {
    KEY: 'privacy-route',
    TITLE: 'Privacy',
    PATH: '/privacy',
  },
  TERMS: {
    KEY: 'terms-route',
    TITLE: 'Terms and Conditions',
    PATH: '/terms-and-conditions',
  },
  EULA: {
    KEY: 'eula-route',
    TITLE: 'End User License Agreement',
    PATH: '/eula',
  },
}
