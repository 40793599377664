import { FC } from 'react'
import EULA from 'components/EULA'
import Footer from 'components/Footer'

const EULAPage: FC = () => {
  return (
    <>
      <EULA />
      <Footer />
    </>
  )
}

export default EULAPage
