import { FC } from 'react'

import cx from 'classnames'

import Avatar from 'components/Avatar'
import { useWindowResize } from 'hooks/useWindowSize'

import './Testimonial.scss'
import Pill from 'components/Pill'

interface ITestimonialProps {
  className?: string
  image: string
  title: string
  text: string
  rating: number
  index: number
  position: string
}

const Testimonial: FC<ITestimonialProps> = ({ className, image, title, text, index, position }) => {
  const [width] = useWindowResize()

  const leftStyle = {
    marginLeft: width < 701 ? '7%' : '20%',
  } as React.CSSProperties

  const rightStyle = {
    marginLeft: width < 701 ? '28%' : '33%',
  } as React.CSSProperties

  return (
    <Pill className={cx(className, 'testimonial')} style={index % 2 === 0 ? leftStyle : rightStyle}>
      <div className='testimonial-avatar'>
        <Avatar
          className={className}
          image={image}
          width={width > 550 ? '225px' : width > 400 ? '150px' : '100px'}
          height={width > 550 ? '225px' : width > 400 ? '150px' : '100px'}
        />
      </div>
      <div className='testimonial-content'>
        <div className='testimonial-content-title'>
          <p>{title}</p>
        </div>
        <div className='testimonial-content-text'>
          <p>{text}</p>
        </div>
        <div className='testimonial-content-position'>
          <p>{position}</p>
        </div>
      </div>
    </Pill>
  )
}

export default Testimonial
