import Footer from 'components/Footer'
import TermsAndConditions from 'components/Terms2'
import { FC } from 'react'

const Terms: FC = () => {
  return (
    <>
      <TermsAndConditions />
      <Footer />
    </>
  )
}

export default Terms
